<template>
  <main class="main-content content bgc-grey-200">
    <div>
      <h4 class="c-grey-900 mt-3">ユーザー追加</h4>
    </div>

    <div class="masonry-item">
      <div class="bgc-white p-20 bd card col-md-8">
        <form>
          <!-- 名前 -->
          <div class="form-group">
            <label for="name">名前</label>
            <input
              type="name"
              :class="'form-control h-75' + (errors && errors.name && (errors.name.length > 0) ? ' is-invalid' : '')"
              id="name"
              placeholder="山田 太郎"
              v-model="user.name"
              ref="name"
              data-e2e="register_name"
            />
            <div class="text-danger" v-if="errors && errors.name">
                <div v-for="(msg, index) in errors.name" :key="index">
                  {{ msg }}
                </div>
            </div>
          </div>

          <!-- メールアドレス -->
          <div class="form-group">
            <label for="email">メールアドレス</label>
            <input
              type="email"
              :class="'form-control h-75' + (errors && errors.email && (errors.email.length > 0) ? ' is-invalid' : '')"
              id="email"
              placeholder="sample@example.com"
              v-model="user.email"
              data-e2e="register_email"
            />
            <div class="text-danger" v-if="errors && errors.email">
                <div v-for="(msg, index) in errors.email" :key="index">
                  {{ msg }}
                </div>
            </div>
          </div>

          <!-- メールアドレス注意文 -->
          <div class="mt-3">
            <div class="alert alert-secondary" role="alert">
              <p data-e2e="mail_policy">
                ※メールアドレスでは大文字英語の使用ができません。<br />
                大文字で入力いただいた場合、小文字へ自動変換し登録されます。
              </p>
            </div>
          </div>

          <!-- パスワード -->
          <div class="form-group">
            <label for="password">パスワード</label>
            <input
              type="password"
              :class="'form-control h-75' + (errors && errors.password && (errors.password.length > 0) ? ' is-invalid' : '')"
              id="password"
              placeholder=""
              v-model="user.password"
              data-e2e="register_password"
            />
            <div class="text-danger" v-if="errors && errors.password">
                <div v-for="(msg, index) in errors.password" :key="index">
                  {{ msg }}
                </div>
            </div>
          </div>

          <!-- パスワード再入力 -->
          <div class="form-group">
            <label for="password_confirmation">パスワード再入力</label>
            <input
              type="password"
              :class="'form-control h-75' + (errors && errors.password_confirmation && (errors.password_confirmation.length > 0) ? ' is-invalid' : '')"
              id="password_confirmation"
              placeholder=""
              v-model="user.password_confirmation"
              data-e2e="register_password_confirmation"
            />
            <div class="text-danger" v-if="errors && errors.password_confirmation">
                <div v-for="(msg, index) in errors.password_confirmation" :key="index">
                  {{ msg }}
                </div>
            </div>
          </div>

          <!-- パスワードポリシー  -->
          <PasswordPolicy :passwordPolicyMin="this.passwordPolicyMin" :passwordPolicyMax="this.passwordPolicyMax" />

          <!-- 権限（役割） -->
          <div class="form-group">
            <label for="role">権限</label>
            <div class="col-sm-10">
              <!-- オーナー -->
              <!-- オーナーはNESICサービス運用者による登録のみのため非表示 -->

              <!-- 管理者 -->
              <div class="form-check mb-1" v-if="role === 1">
                <label class="form-check-label">
                  <input
                    :class="'form-check-input' + (errors && errors.role && (errors.role.length > 0) ? ' is-invalid' : '')"
                    type="radio"
                    name="role"
                    :value="2"
                    v-model="user.role"
                    data-e2e="register_role_2"
                  />
                  管理者
                </label>
              </div>

              <!-- ユーザー -->
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    :class="'form-check-input' + (errors && errors.role && (errors.role.length > 0) ? ' is-invalid' : '')"
                    type="radio"
                    name="role"
                    :value="3"
                    v-model="user.role"
                    data-e2e="register_role_3"
                  />
                  一般ユーザー
                </label>
              </div>
            </div>
            <div class="text-danger" v-if="errors && errors.role">
                <div v-for="(msg, index) in errors.role" :key="index">
                  {{ msg }}
                </div>
            </div>
          </div>

          <!-- 権限の注意書き -->
          <div class="mt-3">
            <div class="alert alert-secondary" role="alert">
              <p data-e2e="role_policy_1">※権限ごとに設定可能な人数の上限は以下の通りです。</p>
              <p data-e2e="role_policy_2">【基本設定】</p>
              <p data-e2e="role_policy_3">・オーナー : 1名</p>
              <p data-e2e="role_policy_4">・管理者 : 5名</p>
              <p data-e2e="role_policy_5">・一般ユーザー : 30名</p>
              <br />
              <div class="pt-1">
                <p data-e2e="role_policy_6">
                  ※基本設定以外の人数設定になっている対象アプリについてはSymphonict
                  サービスポータルのマニュアルをご確認ください。
                </p>
              </div>
            </div>
          </div>

          <!-- アプリ  -->
          <div class="form-group">
            <label for="apps">アプリ</label>
            <div
              class="col-sm-10"
              v-for="app in company_apps"
              :key="app.application_id"
              data-e2e="register_select_cloud_service"
            >
              <span v-if="app.view_level >= 0">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="app.application_id"
                      v-model="user.apps"
                      :id="'application' + app.application_id"
                      v-on:click="check_apps(app.application_id)"
                    />
                    {{ app.name }}<span v-if="app.is_otp_used === true" class="asterisk">＊</span>
                    <div v-for="option in company_options" :key="option.application_option_id">
                      <span v-if="option.view_level >= 2 && app.application_id == option.parent_app_id">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :value="option.application_option_id"
                              v-model="user.options"
                              :id="'option' + option.application_option_id"
                              v-on:click="check_options(option.parent_app_id, option.application_option_id)"
                            />
                            {{ option.name }}
                          </label>
                        </div>
                      </span>
                    </div>
                  </label>
                </div>
              </span>
              <span v-if="app.view_level < 0">
                <div class="form-check">
                  <input
                    style="visibility: hidden"
                    class="form-check-input"
                    type="checkbox"
                    :value="app.application_id"
                    :id="'application' + app.application_id"
                    v-model="user.apps"
                  />
                </div>
              </span>
            </div>
            <div class="text-danger" v-if="errors && errors.apps">
              <small>
                <div v-for="(app, index) in errors.apps" :key="index">
                  {{ app }}
                </div>
              </small>
            </div>
          </div>

          <div class="mt-3" v-if="DataAnalysisServices_Description == true || is_otp_used_apps_Description == true">
            <div class="alert alert-secondary" role="alert">
              <!-- Symphonictデータ分析ダッシュボードの説明 -->
              <div v-if="DataAnalysisServices_Description == true">
                <p data-e2e="service_info_1">
                  ※Symphonictデータ分析ダッシュボードの利用開始は、本サイトユーザー登録(更新)後、10営業日いただいております。<br />
                  準備が整い次第、担当者から連絡いたしますので、しばらくお待ちください。
                </p>
              </div>
              <!-- 2段階認証アプリの説明 -->
              <div v-if="is_otp_used_apps_Description == true">
                <p data-e2e="service_info_2">
                  ※<span class="asterisk">＊</span
                  >印がついたアプリを利用するユーザーがログインする際は、二段階認証となります。
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <router-link class="btn btn-outline-info mr-3 btn-sm btn-width" to="/user" role="button">戻る</router-link>
            <button
              v-if="!isLoading"
              type="button"
              class="btn btn-info btn-sm"
              @click="submit()"
              data-e2e="register_submit"
            >
              登録する
            </button>
            <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              登録中…
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import PasswordPolicy from '@/components/PasswordPolicy.vue';
import { apiMixin } from '@/mixins/api.js';

export default {
  name: 'UserRegister',
  mixins: [apiMixin],
  components: {
    PasswordPolicy,
  },
  data() {
    return {
      user: null,
      constants: [],
      company_apps: [],
      company_options: [],
      errors: null,
      isLoading: false,
      passwordPolicyMin: null,
      passwordPolicyMax: null,
      passwordPolicyRegex: null,
      emailRegex: null,
      emailMaxLength: null,
    };
  },
  created() {
    this.initialization();
    this.getConstant();
    this.makeAppList();
  },
  mounted() {
    this.$refs.name.focus();
  },
  methods: {
    initialization() {
      this.user = {
        companyId: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 3,
        apps: [],
        options: [],
      };

      this.errors = {
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
        role: [],
        apps: [],
        options: [],
      };
    },

    async makeAppList() {
      await this.getApps();
    },
    // オプション機能対応
    // 親アプリのチェックを操作
    check_apps: function (app_id) {
      if (document.getElementById('application' + app_id).checked == false) {
        // 親アプリのチェックを外した時、紐づく機能のチェックも外す
        if (app_id == '6') {
          // Box
          // 表示上のチェック解除
          document.getElementById('option1').checked = false;
          document.getElementById('option2').checked = false;
          document.getElementById('option3').checked = false;
          document.getElementById('option4').checked = false;
          document.getElementById('application13').checked = false;
          // 裏で持ってるチェック状況を解除
          this.user.apps = this.user.apps.filter((item) => item !== 13);
          this.user.options = this.user.options.filter((item) => item !== 1);
          this.user.options = this.user.options.filter((item) => item !== 2);
          this.user.options = this.user.options.filter((item) => item !== 3);
          this.user.options = this.user.options.filter((item) => item !== 4);
        }
      }
    },
    // オプション機能のチェックを操作
    check_options: function (parent_app_id, option_id) {
      if (document.getElementById('option' + option_id).checked == true) {
        // オプションにチェックを入れた際、親アプリ、オプションアプリにもチェックを入れる
        this.user.options.push(option_id);
        if (parent_app_id == '6') {
          // Box
          // 画面表示上チェックを入れる
          document.getElementById('application6').checked = true;
          document.getElementById('application13').checked = true;
          // 裏で持ってるチェック状況リストにチェックを入れる
          this.user.apps.push(6);
          this.user.apps.push(13);
          this.user.apps = this.user.apps.filter((value, index, self) => self.indexOf(value) === index);
        }
      } else {
        // オプションにチェックをすべて外した際、オプションアプリもチェックを外す
        this.user.options = this.user.options.filter((item) => item !== option_id);
        if (parent_app_id == '6') {
          // Box
          if (
            document.getElementById('option1').checked == false &&
            document.getElementById('option2').checked == false &&
            document.getElementById('option3').checked == false &&
            document.getElementById('option4').checked == false
          ) {
            document.getElementById('application13').checked = false;
            this.user.apps = this.user.apps.filter((item) => item !== 13);
          }
        }
      }
    },
    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // パスワード最小文字数
        if (this.constants[i].key == 'passwordPolicyMin') {
          this.passwordPolicyMin = this.constants[i].value;
        }
        // パスワード最大文字数
        if (this.constants[i].key == 'passwordPolicyMax') {
          this.passwordPolicyMax = this.constants[i].value;
        }
        // パスワード正規表現
        if (this.constants[i].key == 'passwordPolicyRegex') {
          this.passwordPolicyRegex = this.constants[i].value;
        }
        // email最大桁数
        if (this.constants[i].key == 'emailMaxLength') {
          this.emailMaxLength = Number(this.constants[i].value);
        }
        // email正規表現
        if (this.constants[i].key == 'emailRegex') {
          this.emailRegex = this.constants[i].value;
        }
      }
    },

    // 表示するアプリ情報を取得
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      var applications = (await this.getApi(`users/${this.id}/appList`)) || [];
      this.company_apps = applications.apps;
      this.appIds = applications.appIds;
      this.company_options = applications.options;

      //Symphonictデータ分析ダッシュボードのアプリが選択可能であれば説明文を表示する
      if (this.appIds.indexOf(5) != -1) {
        this.DataAnalysisServices_Description = true;
        this.DataAnalysisServicesUserMaximum = this.company_apps.find((v) => v.application_id === 5).user_maximum;
      }

      //2段階認証アプリが選択可能であれば説明文を表示する
      this.is_otp_used_apps = this.company_apps.filter((e) => e.is_otp_used === true);
      if (this.is_otp_used_apps.length > 0) {
        this.is_otp_used_apps_Description = true;
      }
    },

    // 登録
    async submit() {
      this.user.companyId = this.companyId;
      // バリデーション
      if (this.validater()) {
        await this.postUser();
      } else {
        this.$toasted.error('入力内容に誤りがあります');
      }
    },
    // 入力内容の検証
    validater() {
      this.errors = {
        name: [],
        email: [],
        password: [],
        password_confirmation: [],
        role: [],
        apps: [],
      };

      let result = true;

      // 名前
      if (this.user.name.length === 0) {
        result = false;
        this.errors.name.push('名前を入力してください。');
      } else if (this.user.name.length > 255) {
        result = false;
        this.errors.name.push('名前は255文字以内で入力してください。');
      }

      // メールアドレス
      if (this.user.email.length === 0) {
        result = false;
        this.errors.email.push('メールアドレスを入力してください。');
      } else if (this.user.email.length > this.emailMaxLength) {
        result = false;
        this.errors.email.push('メールアドレスは' + this.emailMaxLength + '文字以内で入力してください。');
      } else if (!RegExp(this.emailRegex).test(this.user.email)) {
        result = false;
        this.errors.email.push('メールアドレスを正しい形式で入力してください。');
      }

      // パスワード
      if (this.user.password.length === 0) {
        result = false;
        this.errors.password.push('パスワードを入力してください。');
      } else if (
        this.user.password.length < this.passwordPolicyMin ||
        this.passwordPolicyMax < this.user.password.length
      ) {
        result = false;
        this.errors.password.push(
          'パスワードの長さは' +
          this.passwordPolicyMin +
          '文字以上' +
          this.passwordPolicyMax +
          '文字以下で入力してください。'
        );
      } else if (!RegExp(this.passwordPolicyRegex).test(this.user.password)) {
        result = false;
        this.errors.password.push('パスワードの条件に則って入力してください。');
      }

      // パスワード再入力
      if (this.user.password_confirmation.length === 0) {
        result = false;
        this.errors.password_confirmation.push('パスワード再入力を入力してください。');
      } else if (this.user.password_confirmation !== this.user.password) {
        result = false;
        this.errors.password_confirmation.push('パスワードと同じ値を入力してください。');
      }

      // アプリ
      if (this.user.apps.length < 1) {
        result = false;
        this.errors.apps.push('アプリを1つ以上選択してください。');
      }

      return result;
    },
    // ユーザー情報を登録する
    async postUser() {
      this.isLoading = true;
      // API共通処理（apiMixin）のPOST用メソッドを実行
      const response = (await this.postApi('users', this.user)) || { result: false };
      this.isLoading = false;

      if (response.result) {
        this.$toasted.success('更新要求が完了しました');
        setTimeout(this.$router.push({ path: '/user' }), 3000);
      } else if (response.message) {
        this.$toasted.error('入力内容に誤りがあります');

        if (response.errors) {
          this.errors = response.errors;
        }

        // 暫定対処 使用されているメアド入力時にエラー内容が表示されない問題
        if (response.message == '既に登録されているメールアドレスのため登録できません') {
          this.errors.email.push('既に登録されているメールアドレスのため登録できません。');
        }

      } else if (response.exclusive) {
        this.$toasted.error(response.exclusive);
      } else {
        // なにもしない
      }
    },
  },
  computed: {
    companyId() {
      return this.$store.getters['auth/companyId'];
    },
    role() {
      return this.$store.getters['auth/role'];
    },
    id() {
      return this.$store.getters['auth/id'];
    },
  },
};
</script>

<style scoped>
.btn-width {
  width: 96px;
}

.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left: 1em;
  text-indent: -1em;
  margin: 0;
}

.asterisk {
  color: #ff6c9e;
  /* color: red; */
  font-weight: bold;
  text-indent: 2em;
}
</style>
