<template>
  <main class="main-content content bgc-grey-200">
    <div class="row md-1 bd-highlight mb-1 align-items-center">
      <div class="bd-highlight">
        <h4 class="c-grey-900 mt-3 ml-3" data-e2e="page_title_name">お問い合わせ</h4>
      </div>
    </div>

    <template v-if="this.appsCount1 >= 1">
      <div class="row mb-4">
        <div class="p-1 bd-highlight">
          <h4 class="block_title c-grey-900 font-weight-bold mt-3 mb-3" data-e2e="block_title_name1">
            下記サービスの技術的なお問い合わせ
          </h4>
        </div>

        <div class="w-75">
          <div class="service_image flex-row bd-highlight card-deck">
            <img v-for="app in apps1" :key="app.id" class="img-width" :src="app.logo_url" :alt="app.name" />
          </div>
          <div class="question_list_button d-flex flex-row bd-highlight ml-2" data-e2e="question_list_block">
            <template>
              <!-- 各ボタン -->
              <a
                v-for="item in items"
                :key="item.item_id"
                :class="item.color"
                class="card-content card mx-2"
                v-bind:href="item.item_url"
                :target="item.is_extarnal_site ? '_blank' : false"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner "></div></div>'
                :title="item.tooltip"
              >
                <div class="font-weight-bold" data-e2e="question_title">
                  <h3>{{ item.title }}</h3>
                </div>
                <br />
                <p class="text-body" v-html="item.description"></p>
              </a>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="this.appsCount2 >= 1">
      <div class="row mb-3">
        <div class="p-1 bd-highlight">
          <h4 class="block_title c-grey-900 font-weight-bold mt-3 mb-3" data-e2e="block_title_name1">
            下記サービスの技術的なお問い合わせ
          </h4>
        </div>

        <div class="w-75">
          <div class="service_image flex-row bd-highlight card-deck">
            <img v-for="app in apps2" :key="app.id" class="img-width" :src="app.logo_url" :alt="app.name" />
          </div>
          <div class="question_list_button d-flex flex-row bd-highlight ml-2" data-e2e="question_technical_list_block">
            <template>
              <!-- 各ボタン -->
              <a
                v-for="item in itemsTechnical"
                :key="item.item_id"
                :class="item.color"
                class="card-content card mx-2"
                v-bind:href="item.item_url"
                :target="item.is_extarnal_site ? '_blank' : false"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner "></div></div>'
                :title="item.tooltip"
              >
                <div class="font-weight-bold" data-e2e="question_technical_title">
                  <h3>{{ item.title }}</h3>
                </div>
                <br />
                <p class="text-body" v-html="item.description"></p>
              </a>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="this.appsCount3 >= 1">
      <div class="row mb-3">
        <div class="p-1 bd-highlight">
          <h4 class="block_title c-grey-900 font-weight-bold mt-3 mb-3" data-e2e="block_title_name1">
            Microsoft 365 の技術的なお問い合わせ
          </h4>
        </div>

        <div class="w-75">
          <!-- <div class="service_image flex-row bd-highlight card-deck">
            <img v-for="app in apps3" :key="app.id" class="img-width" :src="app.logo_url" :alt="app.name" />
          </div> -->
          <div class="question_list_button d-flex flex-row bd-highlight ml-2" data-e2e="question_m365_list_block">
            <template>
              <!-- 各ボタン -->
              <a
                v-for="item in itemsTechnical2"
                :key="item.item_id"
                :class="item.color"
                class="card-content card mx-2"
                v-bind:href="item.item_url"
                :target="item.is_extarnal_site ? '_blank' : false"
                data-toggle="tooltip"
                data-placement="right"
                data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner "></div></div>'
                :title="item.tooltip"
              >
                <div class="font-weight-bold" data-e2e="question_m365_title">
                  <h3>{{ item.title }}</h3>
                </div>
                <br />
                <p class="text-body" v-html="item.description"></p>
              </a>
            </template>
          </div>
        </div>
        <h5 style="color: #ff6c9e; font-weight: bold; text-indent: 2em" data-e2e="manual_m365_block">
          ※ログイン方法やパスワードの確認は
          <a
            href="https://nesicjp.box.com/s/9ntuj1kqbn0owftpc7ns79ftvvxla2rm"
            target="_blank"
            rel="noopener"
            style="color: #05b2f0; text-decoration: underline"
          >
            こちら
          </a>
        </h5>
      </div>
    </template>

    <div class="row">
      <div class="p-2 bd-highlight">
        <h4 class="block_title c-grey-900 mt-3 font-weight-bold mt-3 mb-3" data-e2e="block_title_name3">
          本サイト(Symphonictサービスポータル)に関するお問い合わせ
        </h4>
      </div>

      <div class="w-75">
        <div class="question_list_button d-flex flex-row bd-highlight ml-2" data-e2e="question_contract_list_block">
          <template>
            <!-- 各ボタン -->
            <a
              v-for="item in itemsContract"
              :key="item.item_id"
              :class="item.color"
              class="card-content card mx-2"
              v-bind:href="item.item_url"
              :target="item.is_extarnal_site ? '_blank' : false"
              data-toggle="tooltip"
              data-placement="right"
              data-template='<div class="tooltip tooltip-custom tooltip-custom-opacity-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner "></div></div>'
              :title="item.tooltip"
            >
              <div class="font-weight-bold" data-e2e="question_contract_title">
                <h3>{{ item.title }}</h3>
              </div>
              <br />
              <p class="text-body" v-html="item.description"></p>
            </a>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'Question',
  mixins: [apiMixin, tooltipMixin],
  data() {
    return {
      items: [],
      apps: [],
      apps1: [],
      apps2: [],
      apps3: [],
      appsCount1: '',
      appsCount2: '',
      appsCount3: '',
      itemsTechnical: [],
      itemsTechnical2: [],
      itemsContract: [],
    };
  },
  created() {
    this.getApps();
    this.getButtons();
  },
  updated() {
    this.initTooltip();
  },
  methods: {
    // 表示するアプリ情報を取得
    async getButtons() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.items = (await this.getApi(`commonItems?group=question`)) || [];
      if (this.items.length === 0) {
        this.$router.push({ path: '/404' });
      }
    },
    async getApps() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.apps = (await this.getApi(`users/${this.id}/apps?group=master`)) || [];

      //Canarioと映像AIサービスのアイコンは中段に表示する
      this.apps1 = [];
      this.apps2 = [];
      this.apps3 = [];
      let apps1 = [];
      let apps2 = [];
      let apps3 = [];
      // apps.array.forEach(app => {

      this.apps.forEach(function (app) {
        if (JSON.stringify(app.id) == '11') {
          apps3.push(app);
        } else if (JSON.stringify(app.id) == '9' || JSON.stringify(app.id) == '10' || JSON.stringify(app.id) == '14' || JSON.stringify(app.id) == '15' || JSON.stringify(app.id) == '16' || JSON.stringify(app.id) == '17') {
          apps2.push(app);
        } else {
          apps1.push(app);
        }
      });

      this.apps1 = apps1;
      this.appsCount1 = this.apps1.length;
      this.apps2 = apps2;
      this.appsCount2 = this.apps2.length;
      this.apps3 = apps3;
      this.appsCount3 = this.apps3.length;
      if (this.appsCount2 > 0) {
        this.itemsTechnical = (await this.getApi(`commonItems?group=questionTechnical`)) || [];
      }
      if (this.appsCount3 > 0) {
        this.itemsTechnical2 = (await this.getApi(`commonItems?group=questionM365`)) || [];
      }
      this.itemsContract = (await this.getApi(`commonItems?group=questionContract`)) || [];
    },
  },

  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
  },
};
</script>

<style scoped>
/* カード */

.card {
  color: #2c8fff;
  border-radius: 25px;
  border-color: #2c8fff;
  float: left;
}

.card-content {
  min-width: 460px;
  max-width: 460px;
  min-height: 123px;
}

.content-top {
  /* padding-top: 40px; */
}

.card-padding {
  padding: 10px;
}

.text-body {
  font-size: 1.1rem;
}

p {
  margin: 0;
  line-height: 1.3rem;
}

.traning {
  background-color: #2c8fff;
  color: white;
}

.card-content {
  display: inline;
}

.img-width {
  /* display: inline-block; */
  /* width: 120px; */
  width: auto;
  height: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}

.block_title {
  padding: 0.4em 0.5em; /*文字の上下 左右の余白*/
  border-left: solid 5px #4398e7; /*左線*/
  white-space: pre-line;
}

.question_list_button {
  position: relative;
  top: 7px;
  /* left:40px; */
}

.service_image {
  position: relative;
  left: 30px;
}
</style>
