<template>
  <div class="wrapper">
    <div class="js-sidebar sidebar" data-color="purple" data-background-color="white">
      <div class="js-sidebar-wrapper sidebar-wrapper">
        <div class="js-sidebar-open sidebar-title h1 text-white text-center font-family-futura">
          <a href="/" data-e2e="top" class="serivce_title font-weight-bold">サービスポータル</a>
        </div>

        <div class="sidebar-sticky">
          <ul class="js-sidebar-index nav flex-column">
            <li class="nav-item">
              <a href="/top" data-e2e="app" class="sidebar-link js-tgt" @click.native="clickEvent('appList')" to="">
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#house"></use>
                </svg>
                <span class="js-sidebar-open">トップページ</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/top#notice-description"
                data-e2e="notice_description"
                class="sidebar-link js-tgt"
                @click.native="clickEvent('appList')"
                to=""
              >
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor"></svg>
                <span class="js-sidebar-open ml-3"> お知らせ</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/top#appList"
                data-e2e="appList"
                class="sidebar-link js-tgt"
                @click.native="clickEvent('appList')"
                to=""
              >
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor"></svg>
                <span class="js-sidebar-open ml-3">アプリ一覧 </span>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/catalog/Manual"
                data-e2e="Manual"
                class="sidebar-link js-tgt"
                @click.native="clickEvent('manual')"
                to=""
              >
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#book"></use>
                </svg>
                <span class="js-sidebar-open">マニュアル</span>
              </a>
            </li>

            <li class="nav-item">
              <a href="/catalog/FAQ" data-e2e="faq" class="sidebar-link js-tgt" @click.native="clickEvent('faq')" to="">
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#question-circle"></use>
                </svg>
                <span class="js-sidebar-open">よくある質問</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/notice"
                data-e2e="notice"
                class="sidebar-link js-tgt"
                @click.native="clickEvent('notices')"
                to=""
              >
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#exclamation-triangle"></use>
                </svg>
                <span class="js-sidebar-open">運用状況</span>
              </a>
            </li>

            <!-- 注意事項 -->
            <li class="nav-item">
              <a
                data-e2e="notes"
                href="/gaibusoushinkiritsu"
              >
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#clipboard-check"></use>
                </svg>
                <span class="js-sidebar-open">注意事項</span>
              </a>
            </li>

            <!-- 管理メニュー -->
            <li class="nav-item" v-if="`${this.role}` != 3">
              <a class="sidebar-link js-tgt" href="#" onclick="adminMenuClick()" data-e2e="admin-menu" id="admin-menu">
                <svg class="bi mr-3" width="26px" height="24px" fill="currentColor">
                  <use xlink:href="/img/bootstrap-icons.svg#gear"></use>
                </svg>
                <span class="js-sidebar-open">管理メニュー</span>
                <svg
                  class="svg-inline--fa fa-angle-right fa-w-8 js-sidebar-open rotate-270"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                  ></path>
                </svg>
                <!-- <i class="js-sidebar-open fas fa-angle-right rotate-90"></i> Font Awesome fontawesome.com -->
              </a>
              <ul class="js-sidebar-open js-sidebar-child" style="display: block">
                <!-- お問い合わせ -->
                <li class="">
                  <a
                    :href="this.question"
                    data-e2e="service-now-help"
                    class="sidebar-link js-tgt"
                    @click.native="clickEvent('question')"
                    to=""
                  >
                    <span class="js-sidebar-open">お問い合わせ </span>
                  </a>
                </li>

                <!-- ユーザー設定 -->
                <li class="">
                  <a
                    href="/user"
                    data-e2e="user"
                    class="sidebar-link js-tgt"
                    @click.native="clickEvent('userSetting')"
                    to=""
                  >
                    <span class="js-sidebar-open">ユーザー設定</span>
                  </a>
                </li>

                <!-- 取扱いサービス -->
                <li class="">
                  <a
                    data-e2e="services"
                    :href="this.appCatalog"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Symphonictサービス一覧ページが表示されます"
                    target="_blank"
                  >
                    <span class="js-sidebar-open"
                      >取扱いサービス
                      <svg width="26px" height="24px" fill="currentColor">
                        <use xlink:href="/img/bootstrap-icons.svg#link-45deg"></use>
                      </svg>
                    </span>
                  </a>
                </li>

                <!-- ダッシュボード -->
                <li class="">
                  <a
                    href="/dashboard"
                    data-e2e="dashboard"
                    class="sidebar-link js-tgt"
                    @click.native="clickEvent('dashBorad')"
                    to=""
                  >
                    <span class="js-sidebar-open">ダッシュボード</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <div class="js-sidebar-open sidebar-footer text-center">
            <!-- <figure><img src="/img/logo_vertical.svg"></figure> -->
            <div class="sidebar-copyright mt-4">
              &copy;NEC Networks &<br class="d-none d-md-block" />
              System Integration Corporation.
            </div>
          </div>
        </div>
        <!-- sidebar-sticky  -->
      </div>
      <!--  sidebar-wrapper  -->
    </div>
    <!--  sidebar  -->
  </div>
</template>

<script>
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'Menu',
  mixins: [apiMixin, tooltipMixin],
  data() {
    return {
      hasNewNotice: null,
      user: '',
      constants: [],
      question: '',
      appCatalog: '',
      count: 0,
      newPeriod: null,
      contactAutoLink: '',
    };
  },
  created() {
    this.getUser();
    this.getConstant();
  },
  updated() {
    this.initTooltip();
  },
  methods: {
    // ログインしているユーザー情報を取得
    async getUser() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.user = (await this.getApi(`users/${this.id}`)) || null;
    },
    // 定数情報を取得
    async getConstant() {
      // API共通処理（apiMixin）のGET用メソッドを実行
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // お問い合わせ
        if (this.constants[i].key == 'question') {
          this.question = this.constants[i].value;
        }
        // アプリカタログ
        if (this.constants[i].key == 'appCatalog') {
          this.appCatalog = this.constants[i].value;
        }
      }
    },
    // 新規お知らせ有無を取得(左メニュクリック時(リンク以外))
    async clickEvent(menuName) {
      if (menuName === 'appList') {
        this.$router.push({ path: '/top' });
      } else if (menuName === 'question') {
        this.$router.push({ path: '/question' });
      } else if (menuName === 'userSetting') {
        this.$router.push({ path: '/user' });
      } else if (menuName === 'dashBorad') {
        this.$router.push({ path: '/dashboard' });
      } else if (menuName === 'notices') {
        this.$router.push({ path: '/notice' });
      } else if (menuName === 'manual') {
        this.$router.push({ path: '/catalog/Manual' });
      } else if (menuName === 'faq') {
        this.$router.push({ path: 'catalog/FAQ' });
      }
    },

    toggleNavbar() {
      this.$store.commit('view/setNavbar', !this.$store.state.view.navbar);
    },
  },
  computed: {
    id() {
      return this.$store.getters['auth/id'];
    },
    role() {
      return this.$store.getters['auth/role'];
    },
  },
};
</script>

<style scoped>
/* サイドバー左上のロゴ画像のimg */

.siderbar-img {
  margin-left: -16%;
}
/* サイドバーのロゴ */

.sidebar-logo {
  padding: 10px 20px !important;
}

/* サイドメニューのサービス名 */
.serivce_title {
  font-size: 24px;
}
/* サイドメニューのテキスト */
.sidebar .nav li a,
.sidebar .nav li .dropdown-menu a {
  margin: 0;
  padding: 4px 25px 4px 63px;
  text-decoration: none;
}
</style>