<template>
  <div class="gaibusoushinkiritsu gaibusoushinkiritsu-main">
    <section
      class="gaibusoushinkiritsu-section gaibusoushinkiritsu-element gaibusoushinkiritsu-section-top-title gaibusoushinkiritsu-section-boxed"
    >
      <div class="gaibusoushinkiritsu-container gaibusoushinkiritsu-column-gap-default">
        <div class="gaibusoushinkiritsu-column gaibusoushinkiritsu-element">
          <div class="gaibusoushinkiritsu-widget-wrap gaibusoushinkiritsu-element-populated">
            <div class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-top-title gaibusoushinkiritsu-widget" data-e2e="gaibusoushinkiritsu_top_title">
              <div class="gaibusoushinkiritsu-widget-container">
                <h2 class="gaibusoushinkiritsu-heading-title">利用者情報の外部送信について</h2>
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-introduction-spacer gaibusoushinkiritsu-widget"
            ></div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-introduction-title gaibusoushinkiritsu-widget"
              data-e2e="gaibusoushinkiritsu_introduction_title"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                <h3 class="gaibusoushinkiritsu-heading-title">1.はじめに</h3>
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-introduction gaibusoushinkiritsu-widget gaibusoushinkiritsu-widget-text-editor"
              data-e2e="gaibusoushinkiritsu_introduction"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                NECネッツエスアイ株式会社（以下、「当社」といいます）では、当社が提供するWebサービス（以下、「本サービス」といいます）において、外部の事業者のサービス（以下、「外部サービス」といいます）を使用しており、それらの使用において必要となる本サービスを利用される方に関する情報を、プライバシー保護を尊重した上で、送信する場合があります。<br />
                送信した情報は、それぞれ送信先の外部サービスを提供する事業者のプライバシーポリシー等に基づいて管理・利用されます。
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-opt-out-spacer gaibusoushinkiritsu-widget"
            ></div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-opt-out-title gaibusoushinkiritsu-widget"
              data-e2e="gaibusoushinkiritsu_opt_out_title"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                <h3 class="gaibusoushinkiritsu-heading-title">2.オプトアウト措置（外部送信の停止等）について</h3>
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-opt-out gaibusoushinkiritsu-widget gaibusoushinkiritsu-widget-text-editor"
              data-e2e="gaibusoushinkiritsu_opt_out"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                お客様は、ブラウザの設定等により、お客様の判断で外部送信の停止等を行うことができる場合があります。<br />
                このような選択をした場合、本サービスを正常にご利用いただけなくなる可能性がありますので、あらかじめご了承ください。
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-gaubusoushin-spacer gaibusoushinkiritsu-widget"
            ></div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-gaubusoushin-title gaibusoushinkiritsu-widget"
              data-e2e="gaibusoushinkiritsu_gaubusoushin_title"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                <h3 class="gaibusoushinkiritsu-heading-title">3.外部送信について</h3>
              </div>
            </div>
            <div
              class="gaibusoushinkiritsu-element gaibusoushinkiritsu-element-gaubusoushin gaibusoushinkiritsu-widget gaibusoushinkiritsu-widget-text-editor"
              data-e2e="gaibusoushinkiritsu_gaubusoushin"
            >
              <div class="gaibusoushinkiritsu-widget-container">
                現在当社が外部送信のために利用している外部サービスに関し、電気通信事業法の外部送信規律に関する規定（電気通信事業法第27条の12）又は送信先となる外部事業者の要請等に基づき公表する事項は、以下のとおりです。<br />
                送信される利用者情報の送信先での利用目的については、「プライバシーポリシーへのリンク」記載のリンク先をご確認ください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="gaibusoushinkiritsu-section gaibusoushinkiritsu-element gaibusoushinkiritsu-section-boxed">
      <div class="gaibusoushinkiritsu-container gaibusoushinkiritsu-column-gap-default">
        <div class="gaibusoushinkiritsu-column gaibusoushinkiritsu-element">
          <div class="gaibusoushinkiritsu-widget-wrap gaibusoushinkiritsu-element-populated">
            <div class="gaibusoushinkiritsu-element gaibusoushinkiritsu-widget">
              <div class="gaibusoushinkiritsu-widget-container">
                <div>
                  <p id="table-title" hidden ></p>
                  <table class="tablepress" data-e2e="gaibusoushinkiritsu_table" aria-describedby="table-title">
                    <thead>
                      <tr class="row-1">
                        <th class="column-1" style="width: 15%">情報の送信先となる名称</th>
                        <th class="column-2" style="width: 15%">情報送信先の<br />サービス名</th>
                        <th class="column-3" style="width: 20%">送信される情報の内容</th>
                        <th class="column-4" style="width: 20%">送信される情報の利用目的</th>
                        <th class="column-5" style="width: 15%">プライバシーポリシーへのリンク</th>
                        <th class="column-6" style="width: 15%">外部送信の停止等の設定</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row-2">
                        <td class="column-1">Google LLC</td>
                        <td class="column-2">Google Analytics</td>
                        <td class="column-3">
                          ・利用ブラウザ<br />
                          ・OS情報<br />
                          ・ロケール(言語設定)<br />
                          ・IPアドレス
                        </td>
                        <td class="column-4">
                          サイト運営<br />
                          アクセス解析
                        </td>
                        <td class="column-5">
                          <a href="https://policies.google.com/privacy?hl=ja" rel="noopener nofollow" target="_blank"
                            >https://policies.google.com/privacy?hl=ja</a
                          >
                        </td>
                        <td class="column-6">
                          <a
                            href="https://policies.google.com/technologies/partner-sites?hl=ja"
                            rel="noopener nofollow"
                            target="_blank"
                            >https://policies.google.com/technologies/partner-sites?hl=ja</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Gaibusoushinkiritsu',
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.gaibusoushinkiritsu-element {
  &.gaibusoushinkiritsu-section-top-title {
    padding: 50px 0px 50px 0px;
  }

  &.gaibusoushinkiritsu-element-top-title {
    > .gaibusoushinkiritsu-widget-container {
      padding: 5px 5px 5px 5px;
      border-style: solid;
      border-width: 1px 8px 1px 8px;
      border-color: #000000;
    }

    text-align: center;

    .gaibusoushinkiritsu-heading-title {
      color: #000000;
      font-family: 'Roboto', Sans-serif;
      font-size: 32px;
      font-weight: 600;
    }
  }

  &.gaibusoushinkiritsu-element-introduction-spacer,
  &.gaibusoushinkiritsu-element-opt-out-spacer,
  &.gaibusoushinkiritsu-element-gaubusoushin-spacer {
    --spacer-size: 20px;
    height: 20px;
  }

  &.gaibusoushinkiritsu-element-introduction-title .gaibusoushinkiritsu-heading-title,
  &.gaibusoushinkiritsu-element-opt-out-title .gaibusoushinkiritsu-heading-title,
  &.gaibusoushinkiritsu-element-gaubusoushin-title .gaibusoushinkiritsu-heading-title {
    color: #2725ad;
    font-family: 'Roboto', Sans-serif;
    font-size: 25px;
    font-weight: 600;
  }

  &.gaibusoushinkiritsu-element-introduction,
  &.gaibusoushinkiritsu-element-opt-out,
  &.gaibusoushinkiritsu-element-gaubusoushin {
    text-align: justify;
    color: #000000;
    font-family: 'Roboto', Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
  }
}

.gaibusoushinkiritsu {
  a {
    box-shadow: none;
    text-decoration: none;
  }
}

.gaibusoushinkiritsu-section {
  position: relative;

  .gaibusoushinkiritsu-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  &.gaibusoushinkiritsu-section-boxed > .gaibusoushinkiritsu-container {
    max-width: 1250px;
  }

  &.gaibusoushinkiritsu-section-stretched {
    position: relative;
    width: 100%;
  }

  &.gaibusoushinkiritsu-section-items-middle > .gaibusoushinkiritsu-container {
    align-items: center;
  }
}

.gaibusoushinkiritsu-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;

  > .gaibusoushinkiritsu-element {
    width: 100%;
  }
}

.gaibusoushinkiritsu .gaibusoushinkiritsu-widget-wrap {
  display: flex;
}

.gaibusoushinkiritsu-widget {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.gaibusoushinkiritsu-column-gap-default > .gaibusoushinkiritsu-column > .gaibusoushinkiritsu-element-populated {
  padding: 10px;
}

.gaibusoushinkiritsu-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: 'Roboto', Sans-serif;
}

.tablepress {
  --text-color: #111;
  --head-text-color: var(--text-color);
  --head-bg-color: #d9edf7;
  --border-color: #ddd;
  --padding: 0.5rem;
  --head-active-bg-color: #049cdb;
  --head-active-text-color: var(--head-text-color);

  border: none;
  border-spacing: 0;
  clear: both;
  margin: 0 auto 1rem;
  table-layout: auto;
  width: 100%;
  font-family: 'Segoe UI', Sans-serif;;

  > * > * > * {
    background: none;
    box-sizing: initial;
    float: none !important;
    padding: var(--padding);
  }

  * + tbody > * > *,
  tbody > * ~ * > * {
    border-top: 1px solid var(--border-color);
  }

  thead {
    > * > th {
      background-color: var(--head-bg-color);
      color: var(--head-text-color);
      font-weight: 700;
      vertical-align: middle;
      word-break: normal;
      text-align: center;
      border: 1px solid #000;
    }
  }

  tbody {
    tr > * {
      color: var(--text-color);
      vertical-align: middle;
      border: 1px solid #000;
    }
  }
}
.gaibusoushinkiritsu-heading-title,
.gaibusoushinkiritsu-widget-container {
  font-family: 'Roboto', Sans-serif;;
}

.column-5,
.column-6 {
  > a {
    background-color: transparent;
    text-decoration: none;
    color: #c36;
  }
}
</style>
